<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        class="rounded ma-2"
        dark
        tile
        v-bind="attrs"
        color="danger"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        Add SMC
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New SMC</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.contact.giveName"
                label="Given Name"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.contact.surname"
                label="Surname"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.password"
                type="password"
                label="Password"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.email"
                label="Email"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.contact.phone"
                label="Phone"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="newUser.contact.preferredName"
                label="Preferred Name"
                required
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="newUser.accepts_terms"
                label="Accepts Terms and Conditions"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          @click="saveUser"
        >
          Save
        </v-btn>
        <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
        >
          {{ text }}

          <template v-slot:action="{attrs}">
            <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  export default {
    name: 'NewSMC',
    components: {
      VuePerfectScrollbar,
    },
    data: () => ({
      menu2: {},
      todoSearch: '',
      todos: [],
      selected: [''],
      /// ///-----/////
      snackbar: false,
      isOpen: false,
      isBlock: false,
      checkbox: false,
      dialog: false,
      isLineThrough: false,
      text: 'Task Name Must Be Required...',
      timeout: 2000,
      // item: 1,
      // itemsTwo: 0,
      drawer: null,
      newEditTodo: '',
      newUser: {
        contact: {},
        role: 'StateUser',
      },
    }),
    computed: {
      filterSearchList () {
        return this.todos.filter((post) => {
          return post.title
                    ?.toLowerCase()
                    .includes(this.todoSearch?.toLowerCase())
        })
      },
    },
    methods: {
      saveUser () {
        this.dialog = false
        this.$store.dispatch('createUser', { newUserDetails: this.newUser, role: 'ROLE_SMC' })
      },
    },
  }
</script>
<style lang="scss" scoped>
.apps-wrapper {
    .nested-sidebar-toggle {
        @media (min-width: 959px) {
            display: none;
        }
    }
}
.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            // overflow-y: scroll;
            // height: 100%;
            padding-top: 3.375rem;
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            padding-top: 3.375rem;
            // overflow-y: scroll;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>
